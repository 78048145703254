import axios from "axios";
import i18n from "../../i18n";

const state = {
    locale: i18n.locale,
    logo: '',
    user: {},
    showFullLoader: false,
    currentMonth: null,
    currentFiscalYear: null,
    client_id: null,
    navigations: [
        {
            icon: "bx bxs-dashboard",
            id: 0,
            label: "menuitems.dashboard.text",
            name: "dashboard",
            navigation: "/dashboard"
        }
    ],
    months: [
        {label: 'साउन', value: 4},
        {label: 'भदौ', value: 5},
        {label: 'असोज', value: 6},
        {label: 'कार्तिक', value: 7},
        {label: 'मंसिर', value: 8},
        {label: 'पुष', value: 9},
        {label: 'माघ', value: 10},
        {label: 'फागुन', value: 11},
        {label: 'चैत', value: 12},
        {label: 'वैशाख', value: 1},
        {label: 'जेठ', value: 2},
        {label: 'असार', value: 3}
    ],
    percentage: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 85, 100]
}
const mutations = {
    locale(state, value) {
        state.locale = value
    },
    user(state, value) {
        state.user = value
    },
    showFullLoader(state, value) {
        state.showFullLoader = value
    },
    currentMonth(state, value) {
        state.currentMonth = value
    },
    currentFiscalYear(state, value) {
        state.currentFiscalYear = value
    },
    client_id(state, value) {
        state.client_id = value
    },
    logo(state, value) {
        state.logo = value
    },
    months(state, value) {
        state.months = value
    },
    percentage(state, value) {
        state.percentage = value
    },
    navigations(state, value) {
        state.navigations.push(value)
    },
}
const getters = {
    locale: state => state.locale,
    user: state => state.user,
    showFullLoader: state => state.showFullLoader,
    currentMonth: state => state.currentMonth,
    currentFiscalYear: state => state.currentFiscalYear,
    client_id: state => state.client_id,
    logo: state => state.logo,
    months: state => state.months,
    percentage: state => state.percentage,
    navigations: state => state.navigations,
}
const actions = {
    getNavigations(context, params) {
        if (context.getters.token) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + params.token
            return new Promise((resolve, reject) => {
                axios.get(`/navigation`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateProfile(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/profile/${data.username}?_method=PUT`, data.formData)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateSecurity(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/profile/security/${data.username}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }
}
export default {
    state,
    mutations,
    actions,
    getters
}
