const Login = () => import (/* webpackChunkName: "common-group" */ './views/account/Login.vue');
const Dashboard = () => import (/* webpackChunkName: "common-group" */'./views/Dashboard/Dashboard.vue');
const Profile = () => import (/* webpackChunkName: "common-group" */'./views/Profile/Profile.vue');
const Security = () => import (/* webpackChunkName: "common-group" */'./views/Profile/Security.vue');

const UserIndex = () => import (/* webpackChunkName: "user-group" */'./views/Users/UserIndex.vue');
const UserForm = () => import (/* webpackChunkName: "user-group" */'./views/Users/UserForm.vue');
const RoleIndex = () => import (/* webpackChunkName: "user-group" */'./views/Role/RoleIndex.vue');
const RoleForm = () => import (/* webpackChunkName: "user-group" */'./views/Role/RoleForm.vue');
// const UserView = () => import (/* webpackChunkName: "program-group" */'./views/Program/ProgramView.vue');

const TrainingIndex = () => import (/* webpackChunkName: "training-group" */ './views/Training/TrainingIndex.vue');
const TrainingForm = () => import (/* webpackChunkName: "training-group" */ './views/Training/TrainingForm.vue');
const TrainingView = () => import (/* webpackChunkName: "training-group" */ './views/Training/TrainingView.vue');
const TrainingReport = () => import (/* webpackChunkName: "training-group" */ './views/Training/TrainingReport.vue');

const ProgramProgressIndex = () => import (/* webpackChunkName: "program-progress-industry" */ './views/ProgramProgress/ProgramProgressIndex');
const ProgramProgressForm = () => import (/* webpackChunkName: "program-progress-industry" */ './views/ProgramProgress/ProgramProgressForm.vue');
const ProgramProgressView = () => import (/* webpackChunkName: "program-progress-industry" */ './views/ProgramProgress/ProgramProgressView.vue');
const ProgramProgressArrange = () => import (/* webpackChunkName: "program-progress-industry" */ './views/ProgramProgress/ProgramProgressArrange.vue');
const MonthlyProgressIndex = () => import (/* webpackChunkName: "program-progress-industry" */ './views/MonthlyProgress/MonthlyProgressIndex');
const MonthlyProgressForm = () => import (/* webpackChunkName: "program-progress-industry" */ './views/MonthlyProgress/MonthlyProgressForm.vue');
const MonthlyProgressView = () => import (/* webpackChunkName: "program-progress-industry" */ './views/MonthlyProgress/MonthlyProgressView.vue');
const MonthlyProgressReport = () => import (/* webpackChunkName: "program-progress-industry" */ './views/MonthlyProgress/MonthlyProgressReport.vue');

const routes = [
    {
        path: '/',
        name: 'login',
        component: Login,
        meta: {
            requiresVisitor: true,
            title: 'Login', rule: true,
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            title: 'Dashboard', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            title: 'Profile', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/security',
        name: 'security',
        component: Security,
        meta: {
            title: 'Security', rule: true,
            requiresAuth: true,
        }
    },

    //Users Management
    {
        path: '/users',
        name: 'user-index',
        params: true,
        component: UserIndex,
        meta: {
            title: 'Users', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/users/create',
        name: 'user-create',
        params: true,
        component: UserForm,
        meta: {
            title: 'Create User', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/users/:username/edit',
        name: 'user-edit',
        params: true,
        component: UserForm,
        meta: {
            title: 'Edit User', rule: true,
            requiresAuth: true,
        }
    },

    //Role Management
    {
        path: '/roles',
        name: 'role-index',
        params: true,
        component: RoleIndex,
        meta: {
            title: 'Roles', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/roles/create',
        name: 'role-create',
        params: true,
        component: RoleForm,
        meta: {
            title: 'Create Role', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/roles/:id/edit',
        name: 'role-edit',
        params: true,
        title: 'Edit Role', rule: true,
        component: RoleForm,
        meta: {
            requiresAuth: true,
        }
    },

    //Training Management
    {
        path: '/trainings',
        name: 'training-index',
        component: TrainingIndex,
        meta: {
            title: 'Trainings', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/trainings/create',
        name: 'training-create',
        component: TrainingForm,
        meta: {
            title: 'Create Training', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/trainings/:uid/edit',
        name: 'training-edit',
        params: true,
        component: TrainingForm,
        meta: {
            title: 'Edit Training', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/trainings/:uid/view',
        name: 'training-view',
        component: TrainingView,
        meta: {
            title: 'View Training', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/trainings/report',
        name: 'training-report',
        component: TrainingReport,
        meta: {
            title: 'Training Report', rule: true,
            requiresAuth: true,
        }
    },

    //Monthly Program Progress
    {
        path: '/monthly-progress',
        name: 'monthly-progress-index',
        component: MonthlyProgressIndex,
        meta: {
            title: 'Monthly Program Progress', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/monthly-progress/create',
        name: 'monthly-progress-create',
        component: MonthlyProgressForm,
        meta: {
            title: 'Create Monthly Program Progress', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/monthly-progress/:uid/edit',
        name: 'monthly-progress-edit',
        params: true,
        component: MonthlyProgressForm,
        meta: {
            title: 'Edit Monthly Program Progress', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/monthly-progress/:uid/view',
        name: 'monthly-progress-view',
        component: MonthlyProgressView,
        meta: {
            title: 'View Monthly Program Progress', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/monthly-progress/report',
        name: 'monthly-progress-report',
        component: MonthlyProgressReport,
        meta: {
            title: 'Monthly Program Progress Report', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/program-progress',
        name: 'program-progress-index',
        component: ProgramProgressIndex,
        meta: {
            title: 'Programs', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/program-progress/create',
        name: 'program-progress-create',
        component: ProgramProgressForm,
        meta: {
            title: 'Create Program', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/program-progress/:id/edit',
        name: 'program-progress-edit',
        params: true,
        component: ProgramProgressForm,
        meta: {
            title: 'Edit Program', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/program-progress/:id/view',
        name: 'program-progress-view',
        component: ProgramProgressView,
        meta: {
            title: 'View Program', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/program-progress/arrange',
        name: 'program-progress-arrange',
        component: ProgramProgressArrange,
        meta: {
            title: 'Arrange Program', rule: true,
            requiresAuth: true,
        }
    },
];

export default routes
