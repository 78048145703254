import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getExpenses(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/expense`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createExpense(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/expense/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeExpense(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/expense`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showExpense(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/expense/${params.id}`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editExpense(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/expense/${params.id}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateExpense(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/expense/${data.id}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getExpenseExportView(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/expense-export`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getExpenseOffice(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/expense-office`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateExpenseStatus(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/expense-status/${data.id}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    resendForReviewExpense(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/expense-resend/${data.id}/review`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    expenseReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/expense-report`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
